<script setup>
	const { text, bgColor, textColor, ellipse, version } = defineProps({
		text: {
			type: String,
			required: false,
			default: '',
		},
		bgColor: {
			type: String,
			required: false,
			default: 'white',
		},
		textColor: {
			type: String,
			required: false,
			default: 'black',
		},
		ellipse: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		version: {
			type: String,
			required: false,
			default: 'v1',
			validator: (value) => ['v1', 'v2', 'v3'].includes(value),
		},
	});
</script>

<template>
	<div class="pill" :style="`--bgColor: ${bgColor}; --textColor: ${textColor}`">
		<div class="ellipse" v-if="ellipse"></div>
		<MessHtml class="text" v-if="text" :html="text" />
		<!-- <slot /> -->
	</div>
</template>

<style lang="scss">
	.pill {
		border-radius: 3.333em;
		padding: 0.75em 20px;
		transition: var(--transition);
		background-color: var(--bgColor);
		display: flex;
		align-items: center;
		gap: 8px;
		width: fit-content;

		.ellipse {
			width: 12px;
			height: 12px;
			background-color: var(--red);
			border-radius: 50%;
		}

		.text {
			color: var(--textColor);
			font-weight: 900;
			line-height: 123.1%;
		}

		.mess-html {
			font-size: 12px;
			letter-spacing: 1.38px;
			text-transform: uppercase;
		}

		&:hover {
			.icon-wrapper {
				margin-left: 0.5em;
			}
		}

		&.red {
			color: var(--red);
			&:hover {
				background-color: var(--red);
				color: var(--white);
			}
		}

		&.dark-gray {
			color: var(--white);
			background-color: var(--gray-900);
			&:hover {
				background-color: var(--white);
				color: var(--gray-900);
			}
		}
	}
</style>
